@import '../../styles/variables';

.errorMessage {
  color: $color-orange;
  font-family: NotoSans-Regular;
  font-size: $fz-textS;
  position: relative;
  width: 100%;
  top: -10px
}
.errMessage {
  position: absolute;
  left: 30px;
  width: 90%;
  top: -10px;
}
