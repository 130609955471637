@import '../../styles/variables';
@import "../../style";

.pageHeader {
  background: $page-header-bgc;
  min-height: 77px;
  border-radius: 8px 8px 0 0;
  padding: 0 50px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $table-header-link-color;
  font-size: $fz-textL;
  font-family: $Montserrat-Bold;
}