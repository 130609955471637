.headerBlock {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.wrapper {
  padding: 20px;
}
.selectWrapper {
  margin: 10px;
}
.buttonWrapper {
  margin: 10px;
}