@import '../../styles/variables';

.menuWrapper {
  display: block;
  padding-top: 24px;
  padding-left: 22px;
}
.logoWrapper {
  margin-bottom: 30px;

}

.menuCategory {
  &:not(:last-child) {
    margin-bottom: 100px;
    padding-top: 20px;
  }
}

.categoryTitle {
  display: block;
  color: $color-menu-title;
  margin-bottom: 15px;
  font-size: $fz-textD;
  font-family: $Montserrat-Bold;
  padding-left: 10px;
  text-transform: uppercase;
}
