.headerBlock {
  display: flex;
}

.wrapper {
  padding: 20px;
}

.selectWrapper {
  margin: 10px;
}

.buttonWrapper {
  margin: 10px;
}
