@import '../../styles/variables';

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  color: $pagination-text-color;
  font-size: $fz-textM;
}

.paginationPrev,
.paginationNext {
  min-width: 60px;
  background-color: transparent;
  border: none;
  color: $color-text-muted;
  position: relative;
  cursor: pointer;
  border: $default-border;
  border-radius: $border-radius;


  &:before {
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    border-right: 2px solid $color-text-muted;
    border-top: 2px solid $color-text-muted;
    transform: rotate(45deg);
    right: 5px;
    bottom: 0;
    top: 0;
    margin: auto 0;
  }
}

.paginationNext {
  display: flex;
  justify-content: flex-start;

}
.paginationPrev {
  display: flex;
  justify-content: flex-end;
  &:before {
    transform: rotate(225deg);
    left: 5px;
    right: auto;

  }
}

.paginationNumbers {
  display: inline-block;
  margin: 0 5px;
}
.paginationButton {
  font-size: 12px;
  border: none;
  background: none;
}
.activePaginationButton {
  background-color: $color-hover;
  border: none;
  border-radius: 3px;
  color: $color-grey-light;
}





