@import '../../../styles/variables';

.wrapper {
  position: absolute;
  padding-top: 30px;
  left: -330px;
  top: 10px;
  display: block;
  min-width: 400px;
  min-height: 100px;
  z-index: 1;
}
.wrapperTimeBlock {
  @extend .title;
  display: flex;
}

.modalBlock {
  display: flex;
  justify-content: center;
  background: linear-gradient($color-primary, #17B0E4);
  padding-top: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  &:before {
    content: '';
    border: 10px solid transparent;
    border-bottom: 10px solid $color-primary;
    position: absolute;
    left: 330px;
    bottom: 202px
  }
}
.userInfoBlock {
  padding-bottom: 30px;
}
.userInfoLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.title {
  padding: 10px;
  font-family: Montserrat-Medium;
  font-size: $fz-textD;
  color: $color-white;
  text-align: center;
}

.timeBlockText {
  display: inline-block;
  margin-left: 8px;
}

.timeBlock {
  display: flex;
  align-items: center;
}

