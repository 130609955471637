@import './src/styles/variables';

.baseTriangle {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	cursor: pointer;
}

.triangleUp {
    @extend .baseTriangle;
	border-bottom: 7px solid $switcher-bg-color;
	margin-bottom: 3px;
}

.triangleDown {
	@extend .baseTriangle;
	border-top: 7px solid $switcher-bg-color;
}

.activeTriangle {
	&.triangleUp {
		border-bottom-color: $color-primary;
	}
	&.triangleDown {
		border-top-color: $color-primary;
	}
}
