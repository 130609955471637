@import "../../../styles/variables";

.modalWrapper_title {
  font-family: NotoSans-Bold;
  font-size: $fz-textD;
}

.modalWrapper {
  border-radius: 5px;
  position: absolute;
  width: 240px;
  z-index: 100;
  top: 0;
  left: 50%;
  background-color: $color-secondary;
  color: $color-white;
  padding: 20px;
}

.modalWrapper::before {
  content: '';
  border: 10px solid transparent;
  border-right: 10px solid #0A6E9D;
  position: absolute;
  right: 100%;
  top: 15px;
}

ul {
  padding: 0;
  margin-top: 10px;
  font-family: NotoSans-Regular;
  font-size: $fz-textM;
}

li {
  margin-top: 20px;
}