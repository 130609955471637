@import './styles/variables';

.container {
  display: flex;
  width: 100%;
  background-color: $page-header-bgc-controls;
}

.menuWrapper {
  display: block;
  width: $menu-width;
  background: linear-gradient(to bottom, $color-primary, $color-hover),
}

.workAreaWr {
  display: flex;
  flex-direction: column;
  width: $work-area-width;
  height: 100vh;
  padding: 0 20px 28px 14px;
}
.icon {
  margin-right: 10px;
  min-width: 30px;
  position: relative;
}

.icons {
  display: flex;
  justify-content: flex-end;
  color: $color-primary;
  padding: 20px;
}

.workArea {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: $color-white;
  overflow-y: auto;
}