@import "../../styles/variables";

.wrapper {
  display: flex;
  align-items: center;
}

.lampClose,
.lampPending,
.lampSuspended,
.lampRegistered,
.defaultVariant,
.lampActive {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.defaultVariant {
  background-color: $color-grey-medium;
}

.lampActive {
  background-color: $color-green;
}

.lampRegistered {
  background-color: $color-blue3;
}

.lampSuspended {
  background-color: $color-orange;
}

.lampPending {
  background-color: $color-grey-medium;
}

.lampClose {
  background-color: $color-grey-medium;
}

.labelStyle {
  opacity: $opacity-half;
  font-size: $fz-textD;
  color: $color-grey;
  padding: 0 5px;
  font-family: $NotoSans-Regular;
  margin-left: 5px;
}