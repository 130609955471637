@import '../../styles/variables';

.wrapper {
  display: flex;
}

.iconActive {
  border: 1px solid $color-hover;
  width: 6px;
  height: 6px;
  border-radius: 4px;
  background-color: $color-hover;
  position: relative;
}
