@import '../../styles/variables';

.formWrapper {
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  height: 100%;
}

.userInfo {
  display: block;
  margin: 20px 0;
  color: $primary-input-color;
  font-weight: $bold;
  font-size: 14px;
  font-family: $Montserrat-Medium;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  min-height: 40px;
  height: 40px;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error {
  color: $color-orange;
  display: block;
  position: absolute;
  bottom: 0;
  font-size: $fz-text-boldS;
}

.formInner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.secondaryWrapper {
  width: 100%;
  display: flex;
  padding-top: 30px;
  position: relative;

  &:before {
    content: '';
    height: 3px;
    top: 0;
    left: 20px;
    right: 20px;
    position: absolute;
    background-color: $color-grey;
  }
}

.secondaryWrapper {
  width: 100%;
  display: flex;
  padding-top: 30px;
  position: relative;

  &:before {
    content: '';
    height: 3px;
    width: 98%;
    top: 0;
    left: 20px;
    right: 20px;
    position: absolute;
    background-color: $color-grey;
  }
}

.labelText {
  display: block;
  margin-bottom: 15px;
}

.controls {
  display: block;
  text-align: right;
  width: 100%;
  padding: 0 40px;
  margin-top: auto;
}

.form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  input {
    width: 100%;
    max-width: 400px;
    min-width: auto;
    box-sizing: border-box;
    min-height: 40px;
    height: 40px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: $input-bgColor-default;
    padding: 0 10px;

    &:focus {
      border-color: $color-hover;
    }
  }

  .select {
    min-height: 34px;
  }
}

.inputItemWrapper {
  padding: 0;
}

.inputItemWrapperPhoneOne {
  margin-right: 10px;
}

.label {
  flex-basis: 25%;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 10px;
  position: relative;

  & > div {
    max-width: 400px;
  }
}

.labelInner {
  width: 100%;
  display: flex;
  margin: 0 -5px;
  padding: 0 5px;
}

.labelWrapper {
  display: flex;
  margin: 0 -10px;
  padding: 0 5px;
}

.labelWrapperPhone {
  display: flex;
  align-items: center;
}

.labelInnerPhone {
  width: 100%;
  display: flex;
  align-items: center;
}

.m {
  flex-basis: 20%;
  width: 20%;
}

.sm {
  flex-basis: 10%;
  width: 10%;
}

.l {
  flex-basis: 25%;
  width: 25%;
}

.xl {
  flex-basis: 30%;
  width: 30%;
}

.xxl {
  flex-basis: 45%;
  width: 45%;
}

.xxm {
  flex-basis: 36%;
  width: 36%;
}

.xxxl {
  flex-basis: 64%;
  width: 64%;
}
