@import '../../styles/variables';

.wrapper {
  width: 100%;
  background-color: $color-white;
  padding: 50px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.block_logo {
  width: 200px;
  height:40px;
  display: block;
  padding-left: 30px;
}