@import '../../../styles/variables';

.form {
  max-height: 490px;
  padding: 15px 20px 30px 20px;
  border: 2px solid transparent;
  border-radius: $border-radius-big;
  display: flex;
  flex-direction: column;
  font-size: $fz-textM;
  width: 350px;
  background-color: $color-white;
}

.welcomeTitle {
  font-family: $Montserrat-Bold;
  font-size: $fz-textS;
  color: $color-text;
  margin-bottom: 7px;
}

.detailsTitle {
  font-family: NotoSans-Regular;
  font-size: $fz-title4;
  margin-bottom: 24px;
  color: $color-text;
}

.title {
  font-family: Montserrat-Bold;
  font-size: $fz-text-boldD;
}

.navLink {
  font-family: Montserrat-Regular;
  font-size: $fz-textD;
  color: $color-link-grey;
  text-decoration: none
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.inputBlock {
  padding-bottom: 10px;
}

.checkBoxWrapper {
  display: inline-flex;
  font-family: Montserrat-Regular;
  font-size: $fz-textD;
  font-weight: $bold;
  padding-bottom: 15px;
}

.error {
  @extend .title;
  color: $color-orange
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px $color-grey-light inset;
}