@import '../../styles/variables';

.default {
  min-width: $input-width;
  min-height: $input-height;
  width: 100%;
}

.inputField {
  padding: 0 10px;
  width: 100%;
  height: 40px;
  color: $color-text;
  background-color: $color-grey-light;
  border: $border-input-login;
  border-radius: $border-radius-big;
  box-sizing: border-box;
}

.errorInputStyle {
  @extend .inputField;
  border-color: $color-orange;
}