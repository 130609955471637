@import "./src/styles/variables";

.wrapper {
  width: 60px;
  height: 30px;
  position: absolute;
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
}

.input_fake {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 3px;
  right: 0;
  bottom: 0;
  background-color: $color-grey-dark;
  border-radius: 34px;
}

.input_fake {
  &:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 1px;
    top: 1px;
    background-color: $color-white;
    transition: .4s;
    border-radius: 50%;
  }
}

input:checked + .input_fake {
  background-color: $color-yellow;
}

input:checked + .input_fake:before {
  transform: translateX(26px);
}

