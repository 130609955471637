//colors
$color-primary: #133E67;
$color-secondary: #0A6E9D;
$color-blue3: #17B0E4;
$color-blue4: #4CC6E4;
$color-yellow: #FFCB35;
$color-orange: #f26a50;
$color-green: #6fcf97;
$color-grey: #c4c4c4;
$color-grey-dark: #a3b0ba;
$color-grey-medium: #d9dfe3;
$color-grey-light: #f5f6f7;
$color-grey-radio: #D8D8D8;
$color-text: #44474a;
$color-text-muted: #110f24;
$color-hover: #17b0e4;
$color-white: #ffffff;
$color-menu-subtitle: #6fbae8;
$color-tabsLink-active: #23bee2;
$color-link-grey: #9da1a5;
$table-header-bgc: rgba(76, 198, 228, 0.05);
$color-menu-title: rgba(255, 255, 255, 0.4);
$color-link: rgba(17, 15, 36, 0.4);
$color-recovery-button: rgba(23, 176, 228, 0.5);

$menu-link-color: $color-grey-light;
$primary-btn-color: $color-hover;
$secondary-btn-color: $color-secondary;
$third-btn-color: $color-grey-medium;
$primary-input-color: $color-text;
$table-header-link-color: $color-primary;
$table-name-color: $color-secondary;
$page-header-bgc: $color-grey-light;
$page-header-bgc-controls: $color-grey-medium;
$actions-dropdown-bg: $color-grey-medium;
$pagination-text-color: $color-hover;
$input-bgColor-default: $color-grey-light;

//font-size titles

$fz-title1: 40px;
$fz-title2: 32px;
$fz-title3: 28px;
$fz-title4: 24px;
$fz-title5: 20px;
$fz-title6: 16px;

//font-size text

$fz-text-boldL: 18px;
$fz-text-boldD: 16px;
$fz-text-boldM: 14px;
$fz-text-boldS: 12px;

$fz-textXL: 24px;
$fz-textL: 18px;
$fz-textD: 16px;
$fz-textM: 14px;
$fz-textS: 12px;

$fz-subTitle: 24px;
$fz-subTitleS: 20px;

//boldness

$thin: 300;
$default: 400;
$bold: 500;
$bolder: 700;

//default line-height

$line-height: 1.2;

//opacity
$opacity-half: 0.5;
$opacity-no: 1;
$opacity-full: 0;

//Menu width

$menu-width: 240px;
$work-area-width: calc(100% - 240px);

//transition

$transition: all 0.2s;

//properties for objects

$input-height: 40px;
$input-heightS: 24px;
$input-width: 160px;

$btn-width: 160px;
$btn-height: 40px;

//border
$default-border: 2px solid $color-hover;
$thin-border: 1px solid $color-hover;
$error-border: 1px solid $color-orange;
$border-input-login: 1px solid #ECECEE;
$border-transparent: 1px solid transparent;
$border-radius: 3px;
$border-radius-table: 5px;
$border-radius-big: 8px;
$checked-icon-border: 2px solid $color-white;

//switchers
$switcher-bg-color: rgba(17, 15, 36, 0.4);
$switcher-place-background: rgba(196, 196, 196, 0.16);
//fonts
$Montserrat-Bold: 'Montserrat-Bold';
$Montserrat-Regular: 'Montserrat-Regular';
$Montserrat-Medium: 'Montserrat-Medium';
$NotoSans-Bold: 'NotoSans-Bold';
$NotoSans-Regular: 'NotoSans-Regular';



