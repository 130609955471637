@import '../../../styles/variables';

.linkWrapper {
  display: flex;
  align-items: flex-end;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.iconWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0;
  padding: 0;
  color: $color-white;

  svg {
    width: 25px;
    height: auto;
  }
}

.linkText {
  font-size: $fz-textD;
  color: #ffffff;
  margin-left: 10px;
  font-family: $Montserrat-Regular;
  align-items: center;
  transition: $transition;
}

.linkActive {
  font-weight: $bolder;
  transition: $transition;
  font-family: $Montserrat-Medium;
}