@import '../../../styles/variables';

.wrapper {
  display: block;
  padding: 10px
}

.wrapperBlock {
  height: 400px;
}

.wrapperDebtCollection {
  width: 700px;
  height: 50px;
  display: flex;
  align-items: center;
  background: $switcher-place-background
}

.blockTitle {
  padding-left: 10px;
  align-items: center;
  width: 350px;
}

.toggleBlock {
  width: 350px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  position: relative;
}

.title {
  font-family: Montserrat-Bold;
  font-size: $fz-textL;
  color: $color-primary
}

.roleTitle {
  width: 100px;
}

.radioButtonBlock {
  display: flex;
  align-items: start;
  justify-content: space-between;
  max-width: 700px;
}

.radioButton {
  display: block;
}

.radio {
  display: flex;
  align-items: start;
  padding-top: 25px;
  margin-bottom: 25px;
}

.line {
  height: 3px;
  background-color: $color-grey;
}

.link {
  display: flex;
  align-items: start;
  text-decoration: underline;
  color: $color-secondary;
  padding-top: 25px;
}

.buttonBlock {
  display: flex;
  align-items: flex-end;
  justify-content: end;
  padding: 40px
}

.debtCollectionBlock {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.debtCollectionBlock:before {
  content: "";
  position: absolute;
  height: 50px;
  width: 700px;
  background-color: rgba(0,0,0,0.1);
}

.debtCollection {
  display: flex;
  align-items: center;
  width: 450px;
}

.linkTo {
  color: $color-secondary;
  font-family: Montserrat-Regular;
  font-size: $fz-textM;
}

.badge {
  display: flex;
  align-items: center;
  font-family: NotoSans-Regular;
  font-size: $fz-textD;
  padding: 10px;
}

input {
  background-color: $color-grey-light;
  border-radius: $border-radius;
  color: $color-text;
  border: $border-input-login;
}

.radioBlock {
  display: flex;
  align-items: center;
}
