@import '../../styles/variables';

.select {
  & input {
    min-height: 24px;
    font-size: 9px;
  }
  & .select__control {
    background-color: $actions-dropdown-bg;
  }
}


