@import '../../styles/variables';

.container_button {
  border: none;
  outline: 0;
  padding: 0;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  position: relative;
  min-width: $btn-width;
  min-height: $btn-height;
  padding: 0 25px 0 15px;
  background-color: $primary-btn-color;
  color: $color-white;
  font-size: $fz-textS;
  border-radius: $border-radius;
  transition: $transition;
  border: none;
  font-family: NotoSans-Bold;

  &:hover {
    background-color: $color-primary;
  }
}

.buttonIconLeft {
  @extend .button;
  padding: 0 15px 0 25px;
  width: 100%;
  justify-content: space-between;
  max-width: 100%;
}

.save {
  @extend .button;
  background-color: $primary-btn-color;
  min-width: 155px;
  min-height: 40px;
  justify-content: center;

}

.cancel {
  @extend .button;
  background-color: $secondary-btn-color;
  min-width: 155px;
  min-height: 40px;
  justify-content: center
}

.edit {
  @extend .button;
  background-color: $third-btn-color;
  min-width: 80px;
  min-height: 25px;
  color: #44474a;
  justify-content: center;
}

.goLogin {
  @extend .button;
  justify-content: center;
  min-width: 330px;
  font-size: $fz-textD;
}

.goBack {
  @extend .button;
  background-color: $color-grey-dark;
  justify-content: center;
  font-size: $fz-textD;
  width: 165px;
}

.continue {
  @extend .button;
  background-color: $color-recovery-button;
  justify-content: center;
  font-size: $fz-text-boldD;
  width: 165px;
}

.return {
  @extend .goBack;
  min-width: 200px;
}

.recoveryPassword {
  @extend .button;
  background-color: $color-recovery-button;
  justify-content: center;
  font-size: $fz-text-boldD;
  min-width: 200px;
}

.logOut {
  @extend .button;
  justify-content: center;
  font-size: $fz-textD;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: $checked-icon-border;
  width: 400px;

}

.logIn {
  @extend .button;
  background-color: $color-blue3;
  justify-content: center;
  font-size: $fz-textD;
}

.disabled {
  @extend .button;
  background-color: $color-grey-dark;
  justify-content: center;
  font-size: $fz-textD;

  &:hover {
    background-color: $color-grey-dark;
  }
}

.forgotPassword {
  border: none;
  font-family: NotoSans-Regular;
  font-size: 14px;
  color: rgba(17, 15, 36, 0.4);
  background-color: $color-white;
}


