@import "../../../styles/variables";

.inputBlock {
  position: relative;
}

.buttonBlock {
  padding-top: 10px;
}

.input {
  box-sizing: border-box;
  width: 100%;
  background-color: $color-grey-light;
  border: $color-grey-light;
  font-family: NotoSans-Regular;
  font-size: $fz-textM;
}

.inputTitle {
  font-family: NotoSans-Bold;
  font-size: $fz-textD;
}

.inputTitleError {
  @extend .inputTitle;
  color: $color-orange
}

.inputError {
  @extend .input;
  border: $error-border
}

.errorMessage {
  font-family: NotoSans-Regular;
  font-size: $fz-textS;
  color: $color-orange;
  position: relative;
  bottom: 5px;
}

.infoBlock {
  display: flex;
  align-items: center;
}

.informationIcon {
  top: 10px;
  left: 20px;
  width: 24px;
  height: 24px;
}

.modalButton {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $color-white;
  border-color: $color-secondary;
  color: $color-secondary;
  position: relative;
  margin: 10px;
}