@import './styles/index';

@font-face {
  font-family: 'Montserrat-Bold';
  src: local(Montserrat), url("./fonts/Montserrat/Montserrat-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local(Montserrat), url("./fonts/Montserrat/Montserrat-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local(Montserrat), url("./fonts/Montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: local(Noto_Sans), url("./fonts/Noto_Sans/NotoSans-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'NotoSans-Regular';
  src: local(Noto_Sans), url("./fonts/Noto_Sans/NotoSans-Regular.ttf");
}

$font-stack: Montserrat-Regular, Montserrat, Helvetica sans-serif;

html, body, body > div {
  height: 100%;
}

body {
  font-family: $font-stack;
  line-height: $line-height;
}

body::-webkit-scrollbar {
  background-color: #fff;
  width: 12px;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

body::-webkit-scrollbar-button {
  display: none;
}

.select__indicator-separator {
  display: none;
}

.select-wrapper {
  .select__control {
    background-color: $actions-dropdown-bg;
    font-size: 12px;
    min-height: 24px;
    overflow: visible;

    .newOrgForm & {
      background-color: $color-grey-light;
      border: none;
    }
  }

  .select__indicators {
    height: 100%;
    align-self: center;
  }
}

.newOrgForm {
  .select__single-value {
    margin-left: 10px;
    font-size: $fz-text-boldM;

    & + div {
      padding: 0;
      margin: 0;
    }
  }

  .select__control {
    max-width: 400px;
    background-color: $color-grey-light;
    border: none;
    font-size: $fz-text-boldM;
  }

  .select__value-container {
    padding: 0 !important;
    width: 50px;
  }

  .select__placeholder {
    padding: 0 10px !important;

    & + div {
      padding: 0;
      margin: 0;
    }
  }
}

.SystemUserSelect {
  width: 170px;
  background-color: $color-white;
  font-size: $fz-text-boldM;
  font-family: NotoSans-Regular;
}

.OpcoUserSelect {
  width: 170px;
  background-color: $color-white;
  font-size: $fz-text-boldM;
  font-family: Montserrat-Medium;
}

.blockLogin {
  background-image: url('./assets/background.png');
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type ='password'] {
  cursor: auto;
}

input{
  cursor: auto
}

input[type ='checkbox'] {
  cursor: auto;
}

