div {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  height: auto;
  box-shadow: none;
  border: none;
}

body, html {
  margin: 0;
}

ul,li,ol,a {
  text-decoration: none;
  list-style: none;
}

input {
  min-height: 40px;
  min-width: 160px;
  display: block;
  font-size: 14px;
  cursor: pointer;
}