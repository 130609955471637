@import "../../styles/variables";

.passwordBlock {
  border-radius: 8px;
  background-color: $color-white;
  padding: 25px;
}

.title {
  font-family: NotoSans-Regular;
  font-size: $fz-textXL;
}

.titleCreatePassword {
  font-family: NotoSans-Regular;
  font-size: $fz-textM;
}