@import '../../styles/variables';

.linkWrapper {
  display: flex;
  padding: 10px;
}

.link {
  color: $table-header-link-color;
  display: inline-block;
  font-size: $fz-textM;
  padding: 5px 40px;
  border-bottom: 4px solid $table-header-link-color;
  transition: $transition;
  margin: 0 1px;
}

.active {
  color: $color-tabsLink-active;
  border-color: $color-tabsLink-active;
}


