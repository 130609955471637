@import "../../../styles/variables";

.password_successfully {
  font-family: NotoSans-Regular;
  font-size: $fz-textM;
}


.button_successfully {
  padding-bottom: 20px;
  width: 100%;
}

