@import './src/styles/variables';


.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-image: url("../../assets/image.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.blockInfo {
  padding-bottom: 50px;
}

.blockButton {
  display: flex;
  justify-content: center;
}

.title {
  font-family: Montserrat-Medium;
  font-size: $fz-title3;
  color: $color-text;
  text-align: center;
}

.createAccountTitle {
  font-family: NotoSans-Regular;
  font-size: $fz-textL;
  color: $color-text;
  text-align: center;
}
