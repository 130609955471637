@import "./src/styles/variables";

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.fakeCheckBox {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: $color-grey-medium;
  border-radius: $border-radius;
}

.fakeCheckBox_financeAccount {
  @extend .fakeCheckBox
}

.input:checked + .fakeCheckBox {
  background-color: $color-green;
}

.fakeCheckBox:after {
  content: "";
  position: absolute;
  display: none;
}

.input:checked +.fakeCheckBox:after {
  display: block;
}

.fakeCheckBox:after {
  content: '';
  width: 10px;
  height: 5px;
  position: absolute;
  border-right: $checked-icon-border;
  border-top: $checked-icon-border;
  transform: rotate(130deg);
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  margin: auto ;
}

.input:checked + .fakeCheckBox_financeAccount {
  background-color: $color-yellow;
}

.input:checked +.fakeCheckBox_financeAccount:after {
  display: block;
}

.fakeCheckBox_financeAccount:after {
  @extend .fakeCheckBox:after
}