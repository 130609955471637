@import '../../styles/variables';
@import '../../style';

.tableHeaderLink {
  font-size: $fz-textM;
  color: $table-header-link-color;
  display: inline-block;
  margin-right: 8px;
  letter-spacing: 0.05px;
  word-spacing: 0.05px;
}

.MuiSelect-filled.MuiSelect-filled {
  padding: 0;
}

.table {
  min-height: 500px;
  border: $default-border;
  border-radius: $border-radius-table;

  .MuiFormLabel-root {
    font-size: $fz-textM;
  }
}

.tableHeader {
  display: flex;
  align-items: center;
  background-color: $table-header-bgc;
  padding: 15px 10px;
  border-bottom: $default-border;
}

.tableHeaderLinkWrapper {
  display: flex;
  align-items: center;
  flex-basis: 14%;
  max-width: 14%;
  width: 100%;
  font-family: $Montserrat-Bold;
  flex-shrink: 1;
  white-space: nowrap;

  &.last_modified_by {
    flex-basis: 17%;
    max-width: 17%;
  }

  &.lastmodified,
  &.last_modified {
    flex-basis: 16%;
    max-width: 16%;
  }

  &.emailaddress {
    flex-basis: 17%;
    max-width: 17%;
  }

  &.status {
    flex-basis: 13%;
    max-width: 13%;
  }

  &.role {
    flex-basis: 10%;
    max-width: 10%;
  }

  &:first-child {
    flex-basis: 24%;
    max-width: 24%;
    margin: 0;
  }

  &:last-child {
    flex-basis: 15%;
    max-width: 15%;
  }

  &:not(:last-child) {
    margin-right: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.Status {
  padding-left: 5px;
}

.tableContentLink {
  flex-basis: 14%;
  max-width: 14%;
  width: 100%;
  color: $color-grey;
  font-size: $fz-textD;
  font-family: $Montserrat-Regular;

  &.last_modified_by {
    flex-basis: 17%;
    max-width: 17%;
  }

  &.lastmodified,
  &.last_modified {
    flex-basis: 16%;
    max-width: 16%;
  }

  &.emailaddress {
    flex-basis: 17%;
    max-width: 17%;
  }

  &.status {
    flex-basis: 13%;
    max-width: 13%;
  }

  &.role {
    flex-basis: 10%;
    max-width: 10%;
  }

  &:not(:last-child) {
    margin-right: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:first-child {
    flex-basis: 24%;
    max-width: 24%;
    color: $color-blue3;
    text-decoration: underline;
    a {
      color: $color-blue3;
    }
  }

  &:last-child {
    flex-basis: 15%;
    max-width: 143px;
  }

  & > div {
    min-width: auto;
    min-height: $input-heightS;
    color: $color-text;
    font-size: $fz-textS;
    font-family: $NotoSans-Bold;
  }
}

.tableContentWrapper {
  min-width: auto;
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  &:not(:last-child) {
    margin-bottom: 10px;
    border-bottom: 2px solid $color-grey-light;
  }
}

.tableContent {
  display: block;
  padding: 15px 10px;
}