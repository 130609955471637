@import '../../../styles/variables';

.RecoveryPassword_block {
  width: 500px;
  min-height: 100px;
  padding: 40px;
  background-color: $color-white;
  border-radius: 5px;
}

.input {
  background-color: $color-grey-light;
  border: none;
  width: 100%;
}

.button_block {
  padding-top: 50px;
}

.title {
  font-family: NotoSans-Regular;
  font-size: $fz-title3;
}

.text {
  font-family: NotoSans-Regular;
  font-size: $fz-textM;
}

.hide_password {
  font-weight: bold;
}

.hide_password__block {
  width: 390px;
  background-color: white;
  padding-left: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
}

.button {
  color: $color-blue3;
  border: none;
  background-color: white;
  font-family: NotoSans-Regular;
  font-size: $fz-textM;
}

.button:hover {
  cursor: pointer;
  color: $table-header-link-color
}

.error_block {
  display: flex;
  position: absolute;
}

.error {
  color: $color-orange;
  position: relative;
  bottom: 12px;
  left: 10px
}