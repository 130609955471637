@import "./src/styles/variables";

.radioBlock {
  display: inline-block;
}

.title {
  padding-left: 10px;
}

.container {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  min-width: 0;
  min-height: 0;
  width: 0;
  height: 0;
}

.fakeRadio {
  height: 25px;
  width: 25px;
  background-color: $color-grey-radio;
  border-radius: 50%;
  position: relative;
}

.input:checked + .fakeRadio {
  background-color: $color-yellow;
}

.fakeRadio:after {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: ease-out all 0.2s;;
}

.input:checked +.fakeRadio:after {
  margin: auto;
  opacity: 1;
  visibility: visible;
}

.fakeRadio:after {
  content: '';
  width: 15px;
  height: 8px;
  border-right: $checked-icon-border;
  border-top: $checked-icon-border;
  transform: rotate(130deg);
  top: 0;
  left: 0;
  right: 0;
  bottom: 5px;
}


