@import "./src/styles/variables";

.wrapper {
  position: absolute;
}

.button {
  border-radius: 50%;
  border: none;
  background-color: $color-grey-medium;
  width: 20px;
  height: 20px;
  position: absolute;
}

.Icon {
  position: relative;
}
