@import './src/styles/variables';

.container {
  display: block;
  position: relative;
}

.backgroundWr {
  background: $color-white;
  z-index: 999;
  overflow: hidden;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.backgroundWrActive {
  @extend .backgroundWr;
  overflow: visible;
  display: flex;
}

.backgroundTransparent {
  opacity: 0.6;
}

.simpleLoader {
  @keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;

  & > div {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;

    & > div {
      position: absolute;
      width: 28px;
      height: 28px;
      border: 3px solid $color-primary;
      border-top-color: $color-grey-dark;
      border-radius: 50%;
      animation: spin 1s linear infinite;
      top: 100px;
      left: 100px;
      box-sizing: content-box;
    }
  }
}
