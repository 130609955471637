@import "../../styles/variables";
@import "../../style";

.termsBlockWrapper {
  width: 470px;
  height: 430px;
  position: absolute;
  border-radius: 8px;
  display: block;
  padding-left: 40px;
  background-color: $color-white;

}

.blockTitle_terms {
  font-family: NotoSans-Regular;
  font-size: $fz-title4;
  color: $color-text
}

.blockTitle_info {
  font-family: NotoSans-Regular;
  font-size: $fz-textM;
  color: $color-text;
}

.blockTerms {
  width: 400px;
  height: 250px;
  overflow-y: auto;
}

.blockTerms_info {
  border: 2px solid $color-white;
  border-radius: 7px;
}
.buttonBlock {
  padding-left: 15px
}

.title {
  font-family: Montserrat-Bold;
  font-size: $fz-title5;
}

.terms {
  font-family: NotoSans-Regular;
  font-size: $fz-textD;
}


::-webkit-scrollbar {
  @extend body::-webkit-scrollbar;
}
::-webkit-scrollbar-track {
  @extend body::-webkit-scrollbar-track
}
::-webkit-scrollbar-thumb {
  @extend body::-webkit-scrollbar-thumb
}
::-webkit-scrollbar-button {
  @extend body::-webkit-scrollbar-button
}
