@import '../../../../styles/variables';

.fieldInput {
  min-height: 40px;
  padding-bottom: 25px;
  position: relative;
}

.error {
  color: $color-orange;
  display: block;
  position: absolute;
  bottom: 0;
  font-size: $fz-text-boldS;
}
